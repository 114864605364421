import type {
  DownloadsProps,
  LogoType,
  PlatformDownloadLinkProps,
} from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import type { Downloads as DownLoadsEntry } from '~src/generated/graphql'

const Downloads = dynamic<DownloadsProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/organisms/Downloads/Downloads').then(
    (design) => design.Downloads,
  ),
)

const DownloadsComponent = ({ entry }: { entry: DownLoadsEntry }): JSX.Element => (
  <Downloads
    id={entry.name!}
    title={entry.title!}
    subTitle={entry.subTitle || ''}
    osLogo={
      <Image
        src={entry.osLogo?.url!}
        objectFit="contain"
        width={entry.osLogo?.width!}
        height={entry.osLogo?.height!}
        alt={entry.osLogo?.description || ''}
      />
    }
    rsLogo={
      <Image
        src={entry.rsLogo?.url!}
        objectFit="contain"
        width={entry.rsLogo?.width!}
        height={entry.rsLogo?.height!}
        alt={entry.rsLogo?.description || ''}
      />
    }
    osButtonUrl={entry.osButtonUrl!}
    osButtonTitle={entry.osButtonTitle!}
    rsButtonUrl={entry.rsButtonUrl!}
    rsButtonTitle={entry.rsButtonTitle!}
    platformDownloadLinks={
      entry.platformListCollection?.items.map(
        (item): PlatformDownloadLinkProps => ({
          url: item?.url ?? '',
          title: item?.title ?? '',
          logo: (item?.platformLogo as LogoType) ?? undefined,
        }),
      ) ?? []
    }
    backgroundImage={
      entry.backgroundImage ? (
        <Image
          src={entry.backgroundImage?.url!}
          alt=""
          quality={80}
          layout="fill"
          objectFit="cover"
          priority
        />
      ) : undefined
    }
  />
)

export default DownloadsComponent
