import type { Brands, VideoProps } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'

import { sendClickEvent } from '~lib/jagex/analytics/api'
import { useAdseId } from '~lib/sessionIds'
import type { Video as VideoEntry } from '~src/generated/graphql'

const Video = dynamic<VideoProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/molecules/Video/Video').then(
    (design) => design.Video,
  ),
)

const VideoComponent = ({ entry }: { entry: VideoEntry }): JSX.Element => {
  const adseId = useAdseId()
  return (
    <Video
      id={entry.name!}
      brand={entry.brand as Exclude<Brands, 'fr'>}
      playButtonText={entry.playButtonText || undefined}
      backgroundImage={entry.backgroundImage?.url!}
      posterImage={entry.posterImage?.url!}
      videoUrl={entry.videoFile?.url!}
      onPlayVideo={() => {
        sendClickEvent({
          id: 'gameplay-trailer',
          action: 'media',
          category: 'ui-action',
          sid: adseId,
        })
      }}
    />
  )
}

export default VideoComponent
