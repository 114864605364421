import Joi from 'joi'

import { GenericBlockType } from '~components/Block/Collection/GenericBlock/types'
import validateObject from '~components/Block/Collection/GenericBlock/validate'
import type { Asset, GenericBlock } from '~src/generated/graphql'

export type BoxOptionEntry = {
  id: string
  icon: Asset
  ctaText: string
  body?: string
} & (BoxOptionsURLAction | BoxOptionsModalAction)

interface BoxOptionsURLAction {
  ctaAction: 'url'
  action: {
    url: string
  }
}
interface BoxOptionsModalAction {
  ctaAction: 'modal'
  action: {
    modal: 'downloads' | 'resubscribe'
  }
}

export interface BoxOptionsEntry {
  id: string
  title?: string
  subtitle?: string
  backgroundImage: Asset | null
  body?: string
  boxes: BoxOptionEntry[]
}

export const validateBoxOption = (boxOptionEntry: GenericBlock): BoxOptionEntry | null => {
  if (boxOptionEntry.type !== GenericBlockType.BoxOption) {
    // eslint-disable-next-line no-console
    console.warn(
      `Could not validate box option; block with a different type provided [type=${boxOptionEntry.type}, id=${boxOptionEntry.sys.id}]`,
    )
    return null
  }

  const schema = Joi.object({
    ctaText: Joi.string().max(30).required(),
    ctaAction: Joi.string().valid('url', 'modal').required(),
    action: Joi.object({
      url: Joi.string().uri(),
      modal: Joi.string().valid('downloads', 'resubscribe'),
    }),
    body: Joi.string().max(250),
  }).when(Joi.object({ ctaAction: Joi.valid('url') }).unknown(), {
    then: Joi.object({ action: Joi.object({ url: Joi.required(), modal: Joi.forbidden() }) }),
    otherwise: Joi.object({ action: Joi.object({ url: Joi.forbidden(), modal: Joi.required() }) }),
  })

  const result = validateObject<BoxOptionEntry>(
    schema,
    boxOptionEntry.sys.id,
    boxOptionEntry.json,
    'BoxOption',
  )

  if (result === null) {
    return null
  }

  if (!boxOptionEntry.image) {
    // eslint-disable-next-line no-console
    console.warn(`BoxOption must have an icon set [id=${boxOptionEntry.sys.id}]`)
    return null
  }

  return {
    ...result,
    id: boxOptionEntry.sys.id,
    icon: boxOptionEntry.image,
  }
}

export const validateBoxOptions = (boxOptionsEntry: GenericBlock): BoxOptionsEntry | null => {
  const schema = Joi.object({
    title: Joi.string().max(60),
    subtitle: Joi.string().max(60),
    body: Joi.string().max(250),
  })

  const result = validateObject<BoxOptionsEntry>(
    schema,
    boxOptionsEntry.sys.id,
    boxOptionsEntry.json,
    'BoxOptions',
  )
  if (result === null) {
    return null
  }

  const boxes = (boxOptionsEntry.nestedEntriesCollection?.items || [])
    .map((item) => (item ? validateBoxOption(item) : null))
    .filter((item): item is BoxOptionEntry => item !== null)

  if (boxes.length > 3 || boxes.length < 2) {
    // eslint-disable-next-line no-console
    console.warn(
      `Provided ${boxes.length} nested entries for BoxOptions with id=${boxOptionsEntry.sys.id}; should be either 2 or 3`,
    )

    return null
  }

  return {
    ...result,
    id: boxOptionsEntry.sys.id,
    backgroundImage: boxOptionsEntry.image ?? null,
    boxes,
  }
}
