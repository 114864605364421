import type { ExploreGameProps } from '@jagex-pp/rs-franchise-design'
import { ExploreGames } from '@jagex-pp/rs-franchise-design'
import Image from 'next/image'

import type { Asset, ExploreGames as ExploreGamesEntry } from '~src/generated/graphql'

const ExploreGamesBlock = ({ entry }: { entry: ExploreGamesEntry }): JSX.Element => {
  return (
    <ExploreGames
      backgroundImage={entry.backgroundImage!.url!}
      games={[
        getBlock(
          entry.leftGameBackgroundImage!,
          entry.leftGameLogo,
          entry.leftGameCtaText!,
          entry.leftGameCtaUrl!,
          entry.leftGameDescription,
        ),
        getBlock(
          entry.rightGameBackgroundImage!,
          entry.rightGameLogo,
          entry.rightGameCtaText!,
          entry.rightGameCtaUrl!,
          entry.rightGameDescription,
        ),
      ]}
    />
  )

  function getBlock(
    background: Asset,
    logo: Asset | null | undefined,
    ctaText: string,
    ctaUrl: string,
    description: string | null | undefined,
  ): ExploreGameProps {
    return {
      background: background.url!,
      logo: logo ? (
        <Image
          src={logo.url!}
          alt=""
          quality={80}
          layout="intrinsic"
          width={logo.width!}
          height={logo.height!}
          priority
        />
      ) : null,
      ctaText,
      ctaUrl,
      description: description ?? null,
    }
  }
}

export default ExploreGamesBlock
