import { createBasicStyle, getCoreProps } from '@jagex-pp/markdown-renderer'
import { isAbsoluteUrl } from '@jagex-pp/misc-helpers'
import {
  Icon,
  Link,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from '@jagex-pp/rs-franchise-design'
import type React from 'react'

const tagTypes = ['a', 'table', 'tr', 'td', 'th', 'hr'] as const

type TagType = (typeof tagTypes)[number]

const createDefaultFranchiseStyle = (
  sectionId: string,
): Partial<Record<TagType, React.FC<any>>> => {
  const basicStyle = createBasicStyle()

  return {
    ...basicStyle,
    a({ children, ...props }) {
      const { href } = props
      return (
        <Link
          analyticsAction="link"
          analyticsCategory="link"
          analyticsId={`${sectionId}-link`}
          color="inherit"
          after={
            // TODO: after completing [PGW-1027] add check whether the href is a third party domain
            isAbsoluteUrl(href) ? (
              <Icon icon="external" height="20" width="20" sx={{ mb: 'auto' }} />
            ) : (
              <Icon icon="chevron-right" height="20" width="20" sx={{ mb: 'auto' }} />
            )
          }
          {...props}>
          {children}
        </Link>
      )
    },
    table({ children, ...props }) {
      return (
        <Table brand="fr" {...getCoreProps(props)}>
          {children}
        </Table>
      )
    },
    th({ children, ...props }) {
      return <TableHeaderCell {...getCoreProps(props)}>{children}</TableHeaderCell>
    },
    tr({ children, ...props }) {
      return <TableRow {...getCoreProps(props)}>{children}</TableRow>
    },
    td({ children, ...props }) {
      return <TableCell {...getCoreProps(props)}>{children}</TableCell>
    },
  }
}

export default createDefaultFranchiseStyle
