import { filterMaybe } from '@jagex-pp/misc-helpers'
import type { Brands, LogoType, SocialsProps } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import type { SocialLink, Socials as SocialsEntry } from '~src/generated/graphql'

const Socials = dynamic<SocialsProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/molecules/Socials/Socials').then(
    (design) => design.Socials,
  ),
)

const SocialsComponent = ({ entry }: { entry: SocialsEntry }): JSX.Element => (
  <Socials
    id={entry.name!}
    heading={entry.heading!}
    subHeading={entry.subHeading!}
    brand={entry.brand! as Exclude<Brands, 'fr'>}
    socialLinks={
      entry.socialLinksCollection?.items
        .filter((item): item is SocialLink => filterMaybe(item))
        .map((item) => ({ title: item.logo as LogoType, url: item.url! })) || []
    }
    backgroundImage={entry.backgroundImage?.url ? entry.backgroundImage.url : undefined}
    backgroundImageOverlay={
      entry.backgroundImageOverlay?.url ? (
        <Image
          src={entry.backgroundImageOverlay.url}
          alt={entry.backgroundImageOverlay.title!}
          quality={50}
          objectFit="contain"
          width={entry.backgroundImageOverlay.width!}
          height={entry.backgroundImageOverlay.height!}
        />
      ) : undefined
    }
  />
)

export default SocialsComponent
