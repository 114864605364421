/** @jsxImportSource theme-ui */
import type { BoxOptionsProps } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import type { GenericBlock } from '~src/generated/graphql'

import osLogo from './assets/os-logo.png'
import rsLogo from './assets/rs-logo.png'
import { validateBoxOptions } from './validation'

const BoxOptions = dynamic<BoxOptionsProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/organisms/BoxOptions/BoxOptions').then(
    (design) => design.BoxOptions,
  ),
)

const BoxOptionsBlock = ({ entry }: { entry: GenericBlock }): JSX.Element | null => {
  const boxOptionsEntry = validateBoxOptions(entry)

  if (boxOptionsEntry === null) {
    return null
  }

  const boxOptions = boxOptionsEntry.boxes.map((box) => {
    return {
      icon: (
        <Image
          src={box.icon.url!}
          alt=""
          quality={80}
          layout="intrinsic"
          width={box.icon.width!}
          height={box.icon.height!}
          priority
        />
      ),
      ctaText: box.ctaText,
      variant: box.ctaAction,
      payload: box.ctaAction === 'url' ? box.action.url : box.action.modal,
      body: box.body,
    }
  })

  return (
    <BoxOptions
      id={boxOptionsEntry.id}
      title={boxOptionsEntry.title}
      subtitle={boxOptionsEntry.subtitle}
      backgroundImage={
        boxOptionsEntry.backgroundImage?.url ? (
          <Image
            src={boxOptionsEntry.backgroundImage.url}
            alt=""
            quality={80}
            layout="fill"
            objectFit="cover"
            priority
          />
        ) : undefined
      }
      osLogo={
        <Image
          src={osLogo}
          alt=""
          quality={80}
          width={osLogo.width}
          height={osLogo.height}
          sx={{ maxWidth: ['70%', null, null, '100%'] }}
          priority
        />
      }
      rsLogo={
        <Image
          src={rsLogo}
          alt=""
          quality={80}
          width={rsLogo.width}
          height={rsLogo.height}
          sx={{ maxWidth: ['70%', null, null, '100%'] }}
          priority
        />
      }
      body={boxOptionsEntry.body}
      boxOptions={boxOptions}
    />
  )
}
export default BoxOptionsBlock
