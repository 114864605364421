import type { MarkdownRendererProps } from '@jagex-pp/markdown-renderer'
import type { HeroBannerProps, ThemeType } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import createDefaultFranchiseStyle from '~components/MarkdownRenderer'
import type { HeroBanner as HeroBannerEntry } from '~src/generated/graphql'

const HeroBanner = dynamic<HeroBannerProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/molecules/HeroBanner/HeroBanner').then(
    (design) => design.HeroBanner,
  ),
)

const DynamicMarkdownRenderer = dynamic<MarkdownRendererProps>(() =>
  import('@jagex-pp/markdown-renderer').then((result) => result.MarkdownRenderer),
)

const HeroBannerBlock = ({ entry }: { entry: HeroBannerEntry }): JSX.Element => {
  return (
    <HeroBanner
      id={entry.name!}
      title={entry.title!}
      subTitle={entry.subTitle || ''}
      theme={(entry.theme as ThemeType) ?? undefined}
      body={
        entry.body && (
          <DynamicMarkdownRenderer
            content={entry.body ?? ''}
            markdownStyle={createDefaultFranchiseStyle(entry.name!)}
          />
        )
      }
      foregroundImageLeft={
        entry.foregroundImageLeft ? (
          <Image
            src={entry.foregroundImageLeft?.url!}
            alt=""
            quality={80}
            layout="intrinsic"
            width={entry.foregroundImageLeft?.width!}
            height={entry.foregroundImageLeft?.height!}
            priority
          />
        ) : undefined
      }
      foregroundImageRight={
        entry.foregroundImageRight ? (
          <Image
            src={entry.foregroundImageRight?.url!}
            alt=""
            quality={80}
            layout="intrinsic"
            width={entry.foregroundImageRight?.width!}
            height={entry.foregroundImageRight?.height!}
            priority
          />
        ) : undefined
      }
      backgroundImage={entry.backgroundImage ? entry.backgroundImage?.url! : undefined}
      videoUrl={entry.videoUrl?.url ?? ''}
    />
  )
}
export default HeroBannerBlock
