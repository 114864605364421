import React from 'react'

/**
 * Simple hook to toggle state of anything that
 * can be active or inactive
 */
function useToggle() {
  const [isActive, setActive] = React.useState(false)
  const toggleActive = () => setActive(true)
  const toggleInactive = () => setActive(false)

  return { isActive, toggleActive, toggleInactive }
}

export default useToggle
