import type { CtaCapProps } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import type { CtaCap as CtaCapEntry } from '~src/generated/graphql'

const CtaCap = dynamic<CtaCapProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/organisms/CtaCap/CtaCap').then(
    (design) => design.CtaCap,
  ),
)

const CtaCapComponent = ({ entry }: { entry: CtaCapEntry }): JSX.Element => {
  return (
    <CtaCap
      id={entry.name!}
      backgroundImage={
        <Image
          src={entry?.backgroundImage?.url!}
          alt=""
          quality={50}
          layout="fill"
          objectFit="cover"
        />
      }
      backgroundImageUrl={entry?.backgroundImage?.url!}
      backgroundVideoUrl={entry?.backgroundVideo?.url!}
      osCharacter={
        <Image
          src={entry?.osCharacter?.url!}
          alt={entry?.osCharacter?.description || ''}
          quality={50}
          objectFit="contain"
          width={entry?.osCharacter?.width!}
          height={entry?.osCharacter?.height!}
        />
      }
      rsCharacter={
        <Image
          src={entry?.rsCharacter?.url!}
          alt={entry?.rsCharacter?.description || ''}
          quality={50}
          objectFit="contain"
          width={entry?.rsCharacter?.width!}
          height={entry?.rsCharacter?.height!}
        />
      }
      rsLogo={
        <Image
          src={entry?.rsLogo?.url!}
          alt={entry?.rsLogo?.description || ''}
          quality={50}
          objectFit="cover"
          width={entry?.rsLogo?.width!}
          height={entry?.rsLogo?.height!}
        />
      }
      osLogo={
        <Image
          src={entry?.osLogo?.url!}
          alt={entry?.osLogo?.description || ''}
          quality={50}
          objectFit="cover"
          width={entry?.osLogo?.width!}
          height={entry?.osLogo?.height!}
        />
      }
      osCtaTitle={entry.osCtaTitle!}
      osCtaUrl={entry.osCtaUrl!}
      rsCtaTitle={entry.rsCtaTitle!}
      rsCtaUrl={entry.rsCtaUrl!}
      rsLinkTitle={entry.rsLinkTitle!}
      rsLinkUrl={entry.rsLinkUrl!}
      osLinkTitle={entry.osLinkTitle!}
      osLinkUrl={entry.osLinkUrl!}
    />
  )
}

export default CtaCapComponent
