import type { Entry, PageFranchiseLandingBlocksItem } from '~src/generated/graphql'

import CtaCapComponent from './Collection/CtaCapBlock'
import DevicesComponent from './Collection/Devices'
import DownloadsComponent from './Collection/DownloadsBlock'
import ExploreGamesComponent from './Collection/ExploreGamesBlock'
import FAQComponent from './Collection/FAQBlock'
import GenericBlockComponent from './Collection/GenericBlock'
import HeroBannerComponent from './Collection/HeroBannerBlock'
import HeroComponent from './Collection/HeroBlock'
import ImagePlateListComponent from './Collection/ImagePlateListBlock'
import MembershipComponent from './Collection/MembershipBlock'
import RecoverAccountComponent from './Collection/RecoverAccountBlock'
import SocialsComponent from './Collection/SocialsBlock'
import TitleHeroComponent from './Collection/TitleHeroBlock'
import VideoComponent from './Collection/VideoBlock'

type Typename = NonNullable<PageFranchiseLandingBlocksItem['__typename']>

type EntryWithTypename = Entry & { __typename?: Typename }

const hasEntryTypename = (entry: Entry | null): entry is EntryWithTypename =>
  entry ? Object.prototype.hasOwnProperty.call(entry, '__typename') : false

type ContentTypeMap<T extends Entry = Entry> = Record<
  Typename,
  React.FunctionComponent<{ entry: T }>
>

const contentTypeMap: ContentTypeMap = {
  ImagePlateList: ImagePlateListComponent,
  TitleHero: TitleHeroComponent,
  Devices: DevicesComponent,
  Hero: HeroComponent,
  Membership: MembershipComponent,
  Socials: SocialsComponent,
  Video: VideoComponent,
  CtaCap: CtaCapComponent,
  Faq: FAQComponent,
  HeroBanner: HeroBannerComponent,
  Downloads: DownloadsComponent,
  RecoverAccount: RecoverAccountComponent,
  GenericBlock: GenericBlockComponent,
  ExploreGames: ExploreGamesComponent,
}

const Block = ({ entry }: { entry: Entry | null }): JSX.Element | null => {
  // eslint-disable-next-line no-underscore-dangle
  const typename = hasEntryTypename(entry) ? entry.__typename : undefined

  if (!typename || !entry) return null
  // eslint-disable-next-line security/detect-object-injection
  const Component = contentTypeMap[typename]

  return Component ? <Component entry={entry} /> : null
}

export default Block
