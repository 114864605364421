
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
/** @jsxImportSource theme-ui */
import { Brands, ImagePlateBackground } from '@jagex-pp/rs-franchise-design'
import type { GetStaticPaths, GetStaticProps } from 'next'
import React from 'react'

import Block from '~components/Block'
import { pagesPath } from '~lib/$path'
import { getPageBySlug } from '~lib/contentful/queries/pageBySlug'
import BaseLayout from '~src/components/BaseLayout'
import type {
  ImagePlateList,
  Maybe,
  Page,
  PageFranchiseLandingBlocksItem,
} from '~src/generated/graphql'
import { getAllPages } from '~src/lib/contentful'

type Props = {
  page: Maybe<Page>
  preview: boolean
  generatedDate: string
}

/**
 * The following pages were migrated from a CMS and are now represented as static content
 */
const migratedCmsPages = new Set(['/', 'runescape', 'oldschool', 'returning-players'])

/**
 * Custom error to handle missing vital page
 */
class CmsPageError extends Error {
  constructor(kv: Record<string, unknown>, isVital: boolean) {
    const pageType = isVital ? 'vital' : 'non-vital'
    super(`Missing ${pageType} page in CMS: ${JSON.stringify(kv)}`)
  }
}

 const _getStaticProps: GetStaticProps<Props> = async ({ preview, params }) => {
  let slug = '/'
  if (params?.slug) {
    slug = Array.isArray(params.slug) ? params.slug.join('/') : params.slug
  }

  const page = await getPageBySlug(slug)
  if (!page) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      page,
      preview: preview ?? false,
      generatedDate: new Date().toISOString(),
    },
    revalidate: false, // Content is static, never revalidate
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  // Ensure that pages with slugs like cookies or 404 don't end up breaking our build
  const otherPages = new Set(
    Object.keys(pagesPath).filter((page) => !(page.startsWith('$') || page.startsWith('_'))),
  )

  const pages = (await getAllPages()).filter((page) =>
    page.slug ? !otherPages.has(page.slug) : false,
  )

  // Ensure all static pages exist and fail the build if they don't
  // eslint-disable-next-line unicorn/no-array-for-each
  migratedCmsPages.forEach((vitalSlug) => {
    if (!pages.some(({ slug }) => slug === vitalSlug)) {
      throw new CmsPageError({ vitalSlug }, true)
    }
  })

  // Transform each page slug into an array of path parts (`/` will become `[]` and render the root-most route)
  const paths = pages.map(({ slug }) => {
    const parts = (slug ?? '')
      .split('/')
      .filter((part) => part.length > 0)
      .map((part) => encodeURIComponent(part))

    return {
      params: {
        slug: parts,
      },
    }
  })

  return {
    paths,
    fallback: 'blocking',
  }
}

export default function DynamicPage(props: Props): JSX.Element {
  const { page, preview, generatedDate } = props
  const seo = page?.seo
  const title = page?.title || 'RuneScape'
  const blocks = page?.content?.blocksCollection?.items ?? []
  const footer = page?.content?.footer
  const navigation = page?.content?.navigation

  const imagePlateList = page?.content?.blocksCollection?.items?.find(
    (item: Maybe<PageFranchiseLandingBlocksItem>) => item?.__typename === 'ImagePlateList',
  ) as Maybe<ImagePlateList>

  return (
    <BaseLayout
      title={title}
      preview={preview}
      footer={footer}
      navigation={navigation}
      seo={seo}
      // TODO: handle changing brand based on slug or a new field in the content type to choose the brand here (page?.content?.brand)
      brand="fr"
      generatedDate={generatedDate}>
      {imagePlateList && (
        <ImagePlateBackground
          backgroundImageUrl={imagePlateList?.backgroundImage?.url!}
          brand={imagePlateList?.brand as Omit<Brands, 'fr'>}
        />
      )}
      {blocks.map((block) => (
        <Block key={block?.sys.id} entry={block} />
      ))}
    </BaseLayout>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[[...slug]]',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  