import { filterMaybe } from '@jagex-pp/misc-helpers'
import type { Brands, ImagePlate, ImagePlateListProps } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import type {
  ImagePlate as ImagePlateEntry,
  ImagePlateList as ImagePlateListEntry,
} from '~src/generated/graphql'

const ImagePlateList = dynamic<ImagePlateListProps>(() =>
  import(
    '@jagex-pp/rs-franchise-design/lib/components/organisms/ImagePlateList/ImagePlateList'
  ).then((design) => design.ImagePlateList),
)

const ImagePlateListComponent = ({ entry }: { entry: ImagePlateListEntry }): JSX.Element => {
  const imagePlates = entry.imagePlatesCollection?.items
    .filter((imagePlate): imagePlate is ImagePlateEntry => filterMaybe(imagePlate))
    .map((imagePlate, i: number) => ({
      index: i,
      id: imagePlate.sys.id,
      title: imagePlate.heading!,
      subtitle: imagePlate.subHeading!,
      body: imagePlate.body!,
      featureImage: (
        <Image
          src={imagePlate?.featureImage?.url!}
          alt={imagePlate?.featureImage?.description || ''}
          quality={50}
          objectFit="cover"
          width={imagePlate?.featureImage?.width!}
          height={imagePlate?.featureImage?.height!}
        />
      ),
      featureImageOverlay: (
        <Image
          src={imagePlate?.featureImageOverlay?.url!}
          alt=""
          quality={50}
          objectFit="cover"
          width={imagePlate?.featureImageOverlay?.width!}
          height={imagePlate?.featureImageOverlay?.height!}
        />
      ),
      brand: entry.brand as Omit<Brands, 'fr'>,
      variant: imagePlate.variant as Parameters<typeof ImagePlate>['0']['variant'],
    }))

  return (
    <ImagePlateList
      id={entry.name!}
      backgroundImageUrl={entry.backgroundImage?.url ? entry.backgroundImage.url : undefined}
      backgroundVideo={entry.backgroundVideo?.url ?? undefined}
      brand={entry?.brand as Omit<Brands, 'fr'>}
      imagePlates={imagePlates || []}
    />
  )
}

export default ImagePlateListComponent
