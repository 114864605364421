import { GenericBlockType } from '~components/Block/Collection/GenericBlock/types'
import type { GenericBlock } from '~src/generated/graphql'

import BoxOptionsBlock from './BoxOptions/BoxOptionsBlock'
import LinkPromoComponent from './LinkPromo/LinkPromo'

const GenericBlockComponent = ({ entry }: { entry: GenericBlock }): JSX.Element | null => {
  if (entry.type === GenericBlockType.BoxOptions) {
    return <BoxOptionsBlock entry={entry} />
  }

  if (entry.type === GenericBlockType.LinkPromo) {
    return <LinkPromoComponent entry={entry} />
  }

  // eslint-disable-next-line no-console
  console.warn(
    `Trying to display non-existent generic collection type ${entry.type}, skipping block ${entry.sys.id}`,
  )
  return null
}
export default GenericBlockComponent
