import type {
  Brands,
  DevicesProps,
  LogoType,
  PlatformDownloadLinkProps,
} from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import type { Devices as DevicesEntry } from '~src/generated/graphql'

const Devices = dynamic<DevicesProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/organisms/Devices/Devices').then(
    (design) => design.Devices,
  ),
)

const DevicesComponent = ({ entry }: { entry: DevicesEntry }): JSX.Element => (
  <Devices
    id={entry.name!}
    title={entry.heading!}
    subTitle={entry.subHeading!}
    brand={entry.brand as Exclude<Brands, 'fr'>}
    bgImage={
      entry.backgroundImage?.url && (
        <Image src={entry.backgroundImage.url} objectFit="cover" layout="fill" alt="" />
      )
    }
    desktopImage={
      entry.desktopImage?.url && (
        <Image src={entry.desktopImage.url} objectFit="contain" width="792" height="452" alt="" />
      )
    }
    tabletImage={
      entry.tabletImage?.url && (
        <Image src={entry.tabletImage.url} objectFit="contain" width="410" height="300" alt="" />
      )
    }
    mobileImage={
      entry.mobileImage?.url && (
        <Image src={entry.mobileImage.url} objectFit="contain" width="320" height="158" alt="" />
      )
    }
    ctaText={entry.ctaText!}
    ctaURL={entry.ctaUrl!}
    platformDownloadLinks={
      entry.platformListCollection?.items.map(
        (item): PlatformDownloadLinkProps => ({
          url: item?.url ?? '',
          title: item?.title ?? '',
          logo: (item?.platformLogo as LogoType) ?? undefined,
          brand: entry.brand as Exclude<Brands, 'fr'>,
        }),
      ) ?? []
    }
  />
)

export default DevicesComponent
