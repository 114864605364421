import { createYouTubeEmbedUrl } from '@jagex-pp/misc-helpers'
import type { MembershipProps } from '@jagex-pp/rs-franchise-design'
import { Brands, Divider, Play } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { sendClickEvent } from '~lib/jagex/analytics/api'
import { useAdseId } from '~lib/sessionIds'
import useToggle from '~lib/toggle'
import type {
  Maybe,
  Membership as MembershipEntry,
  MembershipBenefit,
} from '~src/generated/graphql'

const Membership = dynamic<MembershipProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/organisms/Membership/Membership').then(
    (design) => design.Membership,
  ),
)

const membershipBenefitItems = (benefitItem: Maybe<MembershipBenefit>) => ({
  id: benefitItem?.sys.id!,
  title: benefitItem?.title ?? '',
  subTitle: benefitItem?.subTitle ?? '',
})

const MembershipComponent = ({ entry }: { entry: MembershipEntry }): JSX.Element => {
  const { t } = useTranslation()
  const adseId = useAdseId()

  const { isActive, toggleActive, toggleInactive } = useToggle()

  return (
    <>
      <Divider
        id="membership-top-divder"
        brand={entry.brand! as Exclude<Brands, 'fr'>}
        variant={entry.brand === 'os' ? 'top' : 'center'}
      />
      <Membership
        id={entry.name!}
        cookieConsentLink={t('common:cookieconsent-link')}
        cookieConsentText={t('common:cookieconsent-text')}
        brand={entry.brand! as Exclude<Brands, 'fr'>}
        heading={entry.heading!}
        subHeading={entry.subHeading!}
        bgImage={
          entry.bgImage?.url && (
            <Image src={entry.bgImage.url} alt="" quality={50} layout="fill" objectFit="cover" />
          )
        }
        trailerUrl={entry.trailerUrl ? createYouTubeEmbedUrl(entry.trailerUrl) : undefined}
        badgeImage={
          entry.badgeImage?.url && (
            <Image
              src={entry.badgeImage.url}
              alt=""
              quality={50}
              width={200}
              height={200}
              layout="fixed"
              objectFit="contain"
            />
          )
        }
        edgeBadgeImage={
          entry.edgeBadgeImage?.url && (
            <Image
              src={entry.edgeBadgeImage.url}
              alt=""
              quality={50}
              width={870}
              height={870}
              layout="fixed"
              objectFit="cover"
            />
          )
        }
        ctaUrl={entry.ctaUrl!}
        ctaText={entry.ctaText!}
        benefits={
          entry.benefitsCollection?.items.map((benefit) => membershipBenefitItems(benefit)) ?? []
        }
        modalTrigger={
          <span>
            <Play
              title={`${entry.name!}-play-button`}
              brand={entry.brand! as Exclude<Brands, 'fr'>}
              sx={{ marginTop: -45, mb: [4, 5, 5, 0] }}
              onClick={toggleActive}>
              {entry.trailerText || 'Watch trailer'}
            </Play>
          </span>
        }
        modalIsOpen={isActive}
        closeModal={toggleInactive}
        onAfterOpen={() => {
          sendClickEvent({
            id: 'play-trailer',
            action: 'modal',
            category: 'ui-action',
            sid: adseId,
          })
        }}
        onRequestClose={() => {
          sendClickEvent({
            id: 'close-trailer-modal',
            action: 'modal',
            category: 'ui-action',
            sid: adseId,
          })
        }}
      />
      <Divider
        id="membership-bottom-divder"
        brand={entry.brand! as Exclude<Brands, 'fr'>}
        variant={entry.brand === 'os' ? 'bottom' : 'center'}
      />
    </>
  )
}

export default MembershipComponent
