import type { LinkPromoProps } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'

import type { GenericBlock } from '~src/generated/graphql'

const LinkPromo = dynamic<LinkPromoProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/organisms/LinkPromo/LinkPromo').then(
    (design) => design.LinkPromo,
  ),
)

const LinkPromoComponent = ({ entry }: { entry: GenericBlock }): JSX.Element | null => {
  const { t } = useTranslation()
  return (
    <LinkPromo
      id={entry.name!}
      title={t('linkPromo:title')}
      subTitle={t('linkPromo:subTitle')}
      body={t('linkPromo:body')}
      rsCtaTitle={t('linkPromo:rsCtaTitle')}
      rsCtaUrl={t('linkPromo:rsCtaUrl')}
      osrsCtaTitle={t('linkPromo:osrsCtaTitle')}
      osrsCtaUrl={t('linkPromo:osrsCtaUrl')}
    />
  )
}

export default LinkPromoComponent
