import type { MarkdownRendererProps } from '@jagex-pp/markdown-renderer'
import { filterMaybe } from '@jagex-pp/misc-helpers'
import { Divider, Faq, FaqItemProps, theme } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import createDefaultFranchiseStyle from '~components/MarkdownRenderer'
import type { Faq as FAQEntry, FaqItem } from '~src/generated/graphql'

const DynamicMarkdownRenderer = dynamic<MarkdownRendererProps>(() =>
  import('@jagex-pp/markdown-renderer').then((result) => result.MarkdownRenderer),
)

const FAQBlock = ({ entry }: { entry: FAQEntry }): JSX.Element => {
  let faqItems: FaqItemProps[] = []

  if (entry.faqListCollection) {
    faqItems = entry.faqListCollection?.items
      .filter((faqItem): faqItem is FaqItem => filterMaybe(faqItem))
      .map(
        (faqItem): FaqItemProps => ({
          title: faqItem.title!,
          body: (
            <DynamicMarkdownRenderer
              content={faqItem.body ?? ''}
              markdownStyle={createDefaultFranchiseStyle(entry.name!)}
            />
          ),
          id: faqItem.sys.id!,
        }),
      )
  }
  return (
    <>
      <Faq
        id={entry.name!}
        backgroundImage={
          <Image
            src={entry.backgroundImage?.url!}
            alt=""
            quality={80}
            layout="fill"
            objectFit="cover"
            priority
          />
        }
        title={entry.title!}
        subTitle={entry.subTitle || undefined}
        body={
          entry.body && (
            <DynamicMarkdownRenderer
              content={entry.body ?? ''}
              markdownStyle={createDefaultFranchiseStyle(entry.name!)}
            />
          )
        }
        ctaUrl={entry.ctaUrl!}
        ctaTitle={entry.ctaTitle!}
        ctaText={entry.ctaText || undefined}
        faqItems={faqItems}
      />
      <Divider color="black" brand="rs" fill={theme.colors.frLink} variant="center" />
    </>
  )
}

export default FAQBlock
