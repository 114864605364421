import { filterMaybe } from '@jagex-pp/misc-helpers'
import type { Brands, CharacterPlateProps, HeroProps } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import type { CharacterPlate, Hero as HeroI } from '~src/generated/graphql'

const Hero = dynamic<HeroProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/organisms/Hero/Hero').then(
    (design) => design.Hero,
  ),
)

const HeroBlock = ({ entry }: { entry: HeroI }): JSX.Element => {
  let characterPlates: CharacterPlateProps[] = []

  if (entry.characterPlatesCollection) {
    characterPlates = entry.characterPlatesCollection?.items
      .filter((c): c is CharacterPlate => filterMaybe(c))
      .map(
        (c): CharacterPlateProps => ({
          title: c.title!,
          brand: c.brand as Exclude<Brands, 'fr'>,
          primaryCta: {
            url: c.ctaUrl!,
            text: c.ctaText!,
          },
          secondaryCta:
            c.secondaryCtaUrl && c.secondaryCtaText
              ? {
                  url: c.secondaryCtaUrl,
                  text: c.secondaryCtaText,
                }
              : undefined,
          backgroundVideo: c.backgroundVideo?.url!,
          backgroundDesktop: (
            <Image
              src={c.backgroundImageDesktop?.url!}
              alt=""
              quality={50}
              layout="responsive"
              width={c.backgroundImageDesktop?.width!}
              height={c.backgroundImageDesktop?.height!}
              priority
            />
          ),
          backgroundMobile: (
            <Image
              src={c.backgroundImageMobile?.url!}
              alt=""
              quality={50}
              layout="responsive"
              width={c.backgroundImageMobile?.width!}
              height={c.backgroundImageMobile?.height!}
              priority
            />
          ),
          logoImage: (
            <Image
              src={c.logoImage?.url!}
              alt={c.logoImage?.description || ''}
              quality={80}
              layout="responsive"
              width={c.logoImage?.width!}
              height={c.logoImage?.height!}
              priority
            />
          ),
        }),
      )
  }

  return (
    <Hero
      id={entry.name!}
      backgroundImage={
        <Image
          src={entry.backgroundImage?.url!}
          alt=""
          quality={80}
          layout="fill"
          objectFit="cover"
          priority
        />
      }
      headingTitle={entry.headingTitle!}
      headingCaption={entry.headingCaption!}
      ctaTitle={entry.ctaTitle!}
      ctaUrl={entry.ctaUrl!}
      ctaText={entry.ctaText!}
      characterPlates={characterPlates}
    />
  )
}

export default HeroBlock
