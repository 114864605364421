import { createYouTubeEmbedUrl } from '@jagex-pp/misc-helpers'
import { Brands, Play, TitleHero } from '@jagex-pp/rs-franchise-design'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { sendClickEvent } from '~lib/jagex/analytics/api'
import { useAdseId } from '~lib/sessionIds'
import useToggle from '~lib/toggle'
import type { TitleHero as TitleHeroEntry } from '~src/generated/graphql'

const TitleHeroComponent = ({ entry }: { entry: TitleHeroEntry }): JSX.Element => {
  const { t } = useTranslation()
  const adseId = useAdseId()

  const { isActive, toggleActive, toggleInactive } = useToggle()

  return (
    <TitleHero
      id={entry.name!}
      cookieConsentLink={t('common:cookieconsent-link')}
      cookieConsentText={t('common:cookieconsent-text')}
      brand={entry.brand as Exclude<Brands, 'fr'>}
      title={entry.title!}
      trailerUrl={entry.trailerUrl ? createYouTubeEmbedUrl(entry.trailerUrl) : undefined}
      ctaLinkUrl={entry.ctaLinkUrl!}
      ctaLinkTitle={entry.ctaLinkTitle!}
      backgroundImage={entry.backgroundImage?.url!}
      videoUrl={entry.backgroundVideo?.url ?? undefined}
      backgroundImageOverlay={
        entry.backgroundImageOverlay?.url ? (
          <Image
            src={entry.backgroundImageOverlay.url}
            alt={entry.backgroundImageOverlay.title!}
            quality={50}
            objectFit="contain"
            layout="fill"
            priority
          />
        ) : undefined
      }
      featureImage={
        <Image
          src={entry.featureImage?.url!}
          alt={entry.featureImage?.title!}
          quality={90}
          objectFit="cover"
          width={entry.featureImage?.width!}
          height={entry.featureImage?.height!}
          priority
        />
      }
      logoImage={
        <Image
          src={entry.logoImage?.url!}
          alt={entry.logoImage?.title!}
          quality={90}
          objectFit="contain"
          width={entry.logoImage?.width!}
          height={entry.logoImage?.height!}
          priority
        />
      }
      modalTrigger={
        <span>
          <Play
            brand={entry.brand! as Exclude<Brands, 'fr'>}
            sx={{
              ml: [0, null, 6],
              p: [1, null, null, 2],
              svg: { height: [6, null, 8], width: [6, null, 8] },
            }}
            onClick={toggleActive}>
            {entry.trailerText || 'Watch trailer'}
          </Play>
        </span>
      }
      modalIsOpen={isActive}
      closeModal={toggleInactive}
      onAfterOpen={() => {
        sendClickEvent({
          id: 'title-hero-play-trailer',
          action: 'modal',
          category: 'ui-action',
          sid: adseId,
        })
      }}
      onRequestClose={() => {
        sendClickEvent({
          id: 'title-hero-close-trailer-modal',
          action: 'modal',
          category: 'ui-action',
          sid: adseId,
        })
      }}
    />
  )
}

export default TitleHeroComponent
