import type { MarkdownRendererProps } from '@jagex-pp/markdown-renderer'
import type { RecoverAccountProps } from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import createDefaultFranchiseStyle from '~components/MarkdownRenderer'
import type { RecoverAccount as RecoverAccountEntry } from '~src/generated/graphql'

const RecoverAccount = dynamic<RecoverAccountProps>(() =>
  import(
    '@jagex-pp/rs-franchise-design/lib/components/organisms/RecoverAccount/RecoverAccount'
  ).then((design) => design.RecoverAccount),
)
const DynamicMarkdownRenderer = dynamic<MarkdownRendererProps>(() =>
  import('@jagex-pp/markdown-renderer').then((result) => result.MarkdownRenderer),
)

const CtaCapComponent = ({ entry }: { entry: RecoverAccountEntry }): JSX.Element => {
  return (
    <RecoverAccount
      id={entry.name!}
      title={entry.title!}
      subTitle={entry.subTitle!}
      backgroundImage={entry?.backgroundImage?.url!}
      foregroundImageLeft={
        entry.foregroundImageLeft ? (
          <Image
            src={entry?.foregroundImageLeft?.url!}
            alt={entry?.foregroundImageLeft?.description || ''}
            quality={50}
            objectFit="contain"
            width={entry?.foregroundImageLeft?.width!}
            height={entry?.foregroundImageLeft?.height!}
          />
        ) : undefined
      }
      foregroundImageRight={
        entry.foregroundImageRight ? (
          <Image
            src={entry?.foregroundImageRight?.url!}
            alt={entry?.foregroundImageRight?.description || ''}
            quality={50}
            objectFit="contain"
            width={entry?.foregroundImageRight?.width!}
            height={entry?.foregroundImageRight?.height!}
          />
        ) : undefined
      }
      mobileImage={
        entry.mobileImage ? (
          <Image
            src={entry?.mobileImage?.url!}
            alt={entry?.mobileImage?.description || ''}
            quality={50}
            objectFit="contain"
            width={entry?.mobileImage?.width!}
            height={entry?.mobileImage?.height!}
          />
        ) : undefined
      }
      body={
        entry.body && (
          <DynamicMarkdownRenderer
            content={entry.body ?? ''}
            markdownStyle={createDefaultFranchiseStyle(entry.name!)}
          />
        )
      }
      primaryCtaUrl={entry.primaryCtaUrl || ''}
      primaryCtaTitle={entry.primaryCtaTitle || ''}
      secondaryCtaUrl={entry.secondaryCtaUrl || ''}
      secondaryCtaTitle={entry.secondaryCtaTitle || ''}
      videoUrl={entry.backgroundVideo?.url ?? undefined}
    />
  )
}

export default CtaCapComponent
