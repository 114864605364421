import type Joi from 'joi'

const validateObject = <T>(
  schema: Joi.ObjectSchema,
  id: string,
  obj: object,
  objName: string,
): T | null => {
  const { value, error, warning } = schema.validate(obj, {
    allowUnknown: true,
    stripUnknown: true,
    skipFunctions: true,
    convert: true,
  })

  if (warning) {
    // eslint-disable-next-line no-console
    console.warn(`Warnings when trying to validate ${objName} with id=${id}`, warning)
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.warn(`Errors when trying to validate ${objName} with id=${id}`, error)

    return null
  }

  return value as T
}

export default validateObject
